import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { userState } from "store/user";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import navs from "config/navs";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "components/shared";
import { classNameJoin, mobileOS } from "utils/lib";
import { useRecoilState } from "recoil";
import { PWAInstallOpenState } from "store/modal";

interface IProps extends IStyleProps {
  onToggle: () => void;
}

interface IStyleProps {
  toggle: boolean;
}

const GlobalNavigationBar = ({ toggle, onToggle }: IProps) => {
  const [, setIsPWAInstallPopupOpen] = useRecoilState(PWAInstallOpenState);
  const user = useRecoilValue(userState);
  const { pathname } = useLocation();
  const domain = pathname.split("/")[1];
  const isPWA = window.matchMedia("(display-mode: standalone)").matches; // PWA 실행 여부 확인

  const { mySubId } = useMemo(() => {
    if (!user) {
      return { mySubId: null };
    }
    return { mySubId: user.uuid };
  }, [user]);

  const handleOpenPWAInstallPopup = () => {
    setIsPWAInstallPopupOpen(true);
    onToggle();
  };

  return (
    <>
      <Background toggle={toggle} onClick={onToggle} />
      <GNB toggle={toggle}>
        <div className="h-full w-[230px] bg-white border-r border-secondary-100">
          {navs.map((nav, i) => (
            <Wrapper key={i}>
              {nav.map((item) => {
                const { path, name, icon } = item;
                const os = mobileOS();

                // IOS 환경의 PWA가 아닌 경우에만 어플 설치 가이드 버튼 표시
                if (name === "어플 설치 가이드" && os === "I" && !isPWA) {
                  // 모바일 다운로드 가이드 버튼
                  return (
                    <li key={item.name}>
                      <button
                        onClick={handleOpenPWAInstallPopup}
                        className="flex items-center h-[48px] px-4 text-secondary-700 w-full"
                      >
                        <Icon
                          className="w-6 text-center"
                          icon={item.icon as IconProp}
                          fontSize={18}
                        />
                        <span className="ml-3">{item.name}</span>
                      </button>
                    </li>
                  );
                }

                if (name === "주문관리") {
                  return (
                    <li key={path} onClick={onToggle}>
                      <a href={`${path}#${mySubId}`} target="_blank" rel="noreferrer">
                        <div className="flex items-center h-[48px] px-4 text-secondary-700">
                          <Icon className="w-6 text-center" icon={icon as IconProp} />
                          <span className="ml-3">{name}</span>
                        </div>
                      </a>
                    </li>
                  );
                }

                if (path) {
                  return (
                    <li key={path} onClick={onToggle}>
                      <NavLink
                        className={({ isActive }) =>
                          classNameJoin([
                            "flex items-center h-[48px] px-4",
                            isActive || path.indexOf(domain) !== -1
                              ? " text-primary-900 bg-primary-50 font-medium"
                              : "text-secondary-700",
                          ])
                        }
                        to={path}
                      >
                        <Icon className="w-6 text-center" icon={icon as IconProp} />
                        <span className="ml-3">{name}</span>
                      </NavLink>

                      {/* {domain === basename && children && (
                          <ul>
                            {children.map(({ path, name }) => (
                              <li key={path}>
                                <NavLink
                                  className={({ isActive }) =>
                                    classNameJoin([
                                      "block pl-[52px] py-2 text-[15px]",
                                      isActive
                                        ? " text-primary-900"
                                        : "text-secondary-700",
                                    ])
                                  }
                                  to={path}
                                  key={path}
                                >
                                  {name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )} */}
                    </li>
                  );
                }

                return null;
              })}
            </Wrapper>
          ))}
        </div>
      </GNB>
    </>
  );
};

const GNB = styled.nav<IStyleProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  z-index: 999;

  transform: translateX(${(props) => (props.toggle ? "0px" : "-230px")});

  @media (min-width: 1024px) {
    transform: translateX(0px);
  }
`;

const Wrapper = styled.ul`
  position: relative;

  & + & {
    margin-top: 24px;
    padding-top: 24px;

    &:after {
      content: "";
      position: absolute;
      height: 1px;
      width: 80%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: #bebebe; // text-secondary-400
    }
  }
`;

const Background = styled.div<IStyleProps>`
  cursor: pointer;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 998;
  transition: all 0.3s;
  visibility: ${(props) => (props.toggle ? "visible" : "hidden")};
  opacity: ${(props) => (props.toggle ? 1 : 0)};

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export default GlobalNavigationBar;
